.slick-next {
    right: 0 !important;
    width: 100px !important;
}

.slick-prev {
    left: 0 !important;
    z-index: 9;
    width: 100px !important;
}

.slick-prev:before,
.slick-next:before {
    font-size: 34px !important;
    color: black !important;
}

@media (max-width:600px) {
    .Toastify__toast {
        margin-bottom: 20px !important;
    }

    .Toastify__toast-container {
        width: 80vw !important;
        margin: 0 auto !important;
    }

    .Toastify__toast-container--bottom-right {
        top: 20px;
    }
}